import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, CardContent, CircularProgress, Container, Typography } from '@mui/material'
import './App.css';

function App() {
  const [isFetching, setIsFetching] = useState(false);

  const [promoCode, setPromoCode] = useState('');
  const callbackcode = new URLSearchParams(window.location.search).get('code');
  console.log(callbackcode);

  const fetchCode = useCallback(() => {
    fetch('/promo_code?code=' + callbackcode)
      .then(response => {
        if (!response.ok) throw new Error(`status ${response.status}`);
        return response.text();
      })
      .then(code => {
        setPromoCode(code);
        setIsFetching(false);
      }).catch(e => {
        setIsFetching(false);
      })
  }, []);

  useEffect(() => {
    if (callbackcode) {
      console.log('Fetch promo code');
      setIsFetching(true);
      fetchCode();
    }
  }, [callbackcode]);

  const CLIENT_ID = 67700;
  console.log(window.location.origin)
  const URL = `http://www.strava.com/oauth/authorize?client_id=${CLIENT_ID}$&response_type=code&redirect_uri=${window.location.origin}&approval_prompt=force&scope=read`

  return (
    <Container maxWidth="md" s>
      <img style={{ width: '100%', marginBottom: 10 }} src="/strava_group.jpeg"></img>
      <Card sx={{ minWidth: 275, marginBottom: 5 }}>
        <CardContent>
          <Typography variant="h6" color="text.secondary" gutterBottom>
            Challenge septembre 2022
          </Typography>
          <Typography variant="body2">
            🔴 Placer au minimum une activité de 30 minutes TOUS LES JOURS pendant 14 JOURS consécutifs
            <br />
            👉 Le choix du sport n'est pas imposé
            <br />
            👉 Le challenge sera validé si une nouvelle activité est enregistrée TOUS les jours sans discontinuité
            <br />
            💥 A la fin du challenge :
            Tous les participants ayant atteint le challenge bénéficieront d'un code promo de -15% pour un achat sur la E-Boutique ENDUR'ACTIV (frais de port offert à partir de 50 euros )
            <br />
            🟢 DEBUT du challenge le 1 septembre 2022 inclus
            <br />
            🔴 Fin du challenge le 14 septembre 2022 inclus
          </Typography>
        </CardContent>
      </Card>
      {callbackcode ?
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {isFetching ?
            <CircularProgress />
            :
            <div>
              {promoCode ?
                <Typography variant='h6' align='center'>
                  Récupérez votre code promo unique : {promoCode}
                </Typography> :
                <Typography variant='h6' align='center'>
                  Ce compte ne dispose pas de code promo
                </Typography>}
            </div>
          }
        </div>
        :
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography align='center' style={{ marginBottom: 10 }}>
            Se connecter avec STRAVA pour récupérer son code promo
          </Typography>
          <Button href={URL} align='center' variant="contained" style={{
            backgroundColor: "#E34902",
          }}>Connexion avec STRAVA</Button>
        </div>
      }
      <Typography style={{ marginTop: 20, color: 'grey' }}>
        Un problème ? Nous contacter à l'adresse : contact@strava.endur-activ.com (Merci de joindre votre nom de compte STRAVA)
      </Typography>
    </Container >
  );

}

export default App;
